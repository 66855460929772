<template>
  <div>
    <div class="air__utils__heading"> <h5>Órdenes de Producción</h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <a-row type="flex" justify="end" class="mb-4">
      <a-col :span="5">
        <b-button pill variant="primary" :to="{name: 'production_order'}">
          <div class="d-none d-md-block" v-b-tooltip.hover title="Programar Orden de Producción">
            <b-icon icon="plus"/>Programar Orden de Producción
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Programar Orden de Producción">
            <b-icon icon="plus"/>
          </div>
        </b-button>
      </a-col>
    </a-row>
    <!-- Filtros -->
    <a-row :gutter="[24,8]" class="bg-white px-2 mb-4">
      <a-row>
        <searchingBar :components="['filter']" @Fectch="FetchFormulations()"
                  @filterValue="search = $event"/>
      </a-row>
      <a-row>
        <!-- Filtro de plantas controlado por roles y permisos-->
        <a-col :sm="{ span: 12 }" :md="{ span:8}">
          <customDropdownWorkCenterSelect v-model="workCenterId"/>
        </a-col>
        <a-col :sm="{ span: 12 }" :md="{ span:8}">
          <!-- SELECT Tipo de Proceso -->
          <customDropdownSelect v-model="processTypeFilter"
                                 label="Tipo de Proceso" resource="processTypes"/>
        </a-col>
        <a-col :sm="{ span: 12 }" :md="{ span:8}">
          <!-- SELECT Tipo de Formulación -->
          <customDropdownSelect v-model="formulationTypeFilter"
                                 label="Tipo de Formulación" resource="formulationTypes"/>
        </a-col>
        <a-col :sm="{ span: 12 }" :md="{ span:8}">
          <!-- SELECT Turno -->
          <customDropdownSelect v-model="operationShiftFilter"
                                 label="Turno" resource="operationShifts" :filters="filter4OperationShift"/>
        </a-col>
        <a-col :sm="{ span: 12 }" :md="{ span:8}">
          <!--DATEPICKER - Periodo inicial-->
          <a-form-item label="Periodo inicial">
            <a-date-picker  v-model="started_at" size="large"
                            placeholder="Periodo inicial" style="width:100%"
                            format="DD/MM/YYYY" valueFormat="YYYY-MM-DD"/>
          </a-form-item>
        </a-col>
        <a-col :sm="{ span: 12 }" :md="{ span:8}">
          <!--DATEPICKER - Periodo final-->
          <a-form-item label="Periodo final">
            <a-date-picker  v-model="finished_at" size="large"
                            placeholder="Periodo final" style="width:100%"
                            format="DD/MM/YYYY" valueFormat="YYYY-MM-DD"/>
          </a-form-item>
        </a-col>
      </a-row>
    </a-row>
    <hr class="bg-primary">
    <!-- Filtros -->
    <a-row class="mx-n3 bg-white">
      <a-table
        :scroll="{x:1300}"
        bordered
        :dataSource="tableObj.data"
        :columns="tableObj.columns"
        :loading="tableObj.loading"
        :pagination="tableObj.pagination"
        :row-key="record => record.id"
        @change="FetchFormulations">
        <template slot="details" slot-scope="row">
          <a-descriptions :title="`ID Orden de Producción: ${row.code}`"
                          bordered size="small" :column="{ xs: 1, sm: 1, md: 2, lg: 4}">
            <a-descriptions-item label="Turno">
              <strong>{{ row.operation_shifts.name }}</strong>
            </a-descriptions-item>
            <a-descriptions-item label="Tipo de Proceso">
              <strong>{{ row.process_types.name }}</strong>
            </a-descriptions-item>
            <a-descriptions-item label="Inicio">
              <strong>{{ moment(row.started_at).format('DD/MM/YYYY HH:mm') }}</strong>
            </a-descriptions-item>
            <a-descriptions-item label="Fin">
              <strong>{{moment(row.finished_at).format('DD/MM/YYYY')}}</strong>
            </a-descriptions-item>
            <a-descriptions-item label="Horas Trabajadas">
              <strong>{{row.worked_hours}}</strong>
            </a-descriptions-item>
            <a-descriptions-item label="Total de Contenedores">
              <strong>{{row.total_containers}}</strong>
            </a-descriptions-item>
            <a-descriptions-item label="Planta">
              <strong>{{row.work_centers.name}}</strong>
            </a-descriptions-item>
            <a-descriptions-item label="Tipo de Formulación">
              <strong>{{row.formulation_types.name}}</strong>
            </a-descriptions-item>
            <a-descriptions-item label="Cantidad Total Formulada">
              <strong v-if="(row.mixtures && row.mixtures.total_formule_material) || row.total_formule_material">
                {{row.mixtures.total_formule_material || row.total_formule_material}}
              </strong>
              <span v-else> - </span>
            </a-descriptions-item>
            <a-descriptions-item label="Estatus">
              <strong :class="status_class(row.status)">{{row.status}}</strong>
            </a-descriptions-item>
        </a-descriptions>
        </template>
        <template slot="actions" slot-scope="row">
          <b-button pill variant="secondary" :to="{name: 'production_order', params: {id: row.id}}">
           <div class="d-none d-md-block" v-b-tooltip.hover title="Editar">
             <b-icon icon="pencil-square"/> Editar
           </div>
           <div class="d-md-none" v-b-tooltip.hover title="Editar">
             <b-icon icon="pencil-square"/>
           </div>
          </b-button>
          <b-button v-if="row.status === 'Pendiente' || row.status === 'En Proceso'"
                    class="mr-md-1 mb-sm-1" pill variant="success"
                    @click="Conclude(row.id)">
           <div class="d-none d-md-block" v-b-tooltip.hover title="Concluir">
             <b-icon icon="check-all"/> Concluir
           </div>
           <div class="d-md-none" v-b-tooltip.hover title="Concluir">
             <b-icon icon="check-all"/>
           </div>
          </b-button>
        </template>
      </a-table>
    </a-row>
  </div>
</template>

<script>
import { apiV2Mixin } from '@/mixins/ApiV2Mixin'
import moment from 'moment'
import { Modal } from 'ant-design-vue'
export default {
  name: 'operationProcessList',
  mixins: [apiV2Mixin],
  computed: {
    filter4OperationShift() {
      return {
        workCenterId: this.workCenterId,
      }
    },
  },
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'operations',
          breadcrumbName: 'Operaciones',
        },
        {
          name: 'operation_process',
          breadcrumbName: 'Proceso Operativo',
        },
        {
          breadcrumbName: 'Órdenes de Produción',
          aIcon: '',
        },
      ],
      // Filters
      workCenterId: undefined,
      processTypeFilter: undefined,
      formulationTypeFilter: undefined,
      operationShiftFilter: undefined,
      started_at: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      finished_at: moment().add(30, 'days').format('YYYY-MM-DD'),
      search: '',
      // Table
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 20,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Detalles',
            key: 'details',
            width: '85%',
            class: 'text-center small',
            scopedSlots: { customRender: 'details' },
          },
          {
            title: 'Acciones',
            key: 'actions',
            class: 'text-center small',
            scopedSlots: { customRender: 'actions' },
          },
        ],
      },
    }
  },
  methods: {
    // helpers
    status_class(status) {
      switch (status) {
        case 'Pendiente':
          return 'status-tag-warning'
        case 'En Proceso':
          return 'status-tag-real-blue'
        case 'Concluido':
          return 'status-tag-success'
        case 'Cancelado':
          return 'status-tag-danger'
        default:
          return 'status-tag-secondary'
      }
    },
    // fetch
    async FetchFormulations(pagE) {
      const params = {
        orderField: 'id',
        orderType: 'desc',
      }
      // selects
      if (this.workCenterId) params.workCenterId = this.workCenterId // planta
      if (this.processTypeFilter) params.processTypeId = this.processTypeFilter // tipo proceso
      if (this.formulationTypeFilter) params.formulationTypeId = this.formulationTypeFilter // tipo formulacion
      if (this.operationShiftFilter) params.operationShiftId = this.operationShiftFilter // turno
      //  fechas
      if (this.started_at) params.startedAt = this.started_at // fecha inicial
      if (this.finished_at) params.finishedAt = this.finished_at // fecha final
      // search
      if (this.search) params.search = this.search

      await this.GetTableResourcev2(pagE, 'formulations', this.tableObj, params)
    },
    // Conclude
    Conclude(id) {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de concluir la orden de Producción?',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        async onOk () {
          const form = { resourceType: `formulations/finishStatus/${id}`, form: { status: 'Concluido', finished_at: moment().format('YYYY-MM-DD') } }
          await me.PutResourcev2(form, async () => {
            await me.FetchFormulations()
          })
        },
      })
    },
  },
  async mounted() {
    await this.FetchFormulations()
  },
}
</script>
